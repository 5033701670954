
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  props: {
    id: { required: true },
  },

  name: "apps-rfq-details",
  components: {},

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const myData = ref<MyDataStructure[]>([]);
    const tableData = ref<TableData[]>([]);
    const pqData = ref<PQData[]>([]);
    const loading = ref<boolean>(false);

    const router = useRouter();
    const store = useStore();

    onMounted(async () => {
      await getPurchaseQuotationDetails();
      await getPurchaseRequisitonListDetail();
      setCurrentPageBreadcrumbs("Request For Quotation", []);
    });

    interface TableData {
      sr_no: number;
      pq_detail_id: number;
      pq_id: number;
      request_ref: string;
      mfg_company_name: string;
      category_name: string;
      category_short_name: string;
      description: Record<string, string>[];
      pq_quantity: string;
      uom: string;
      status_id: string;
    }

    interface PQData {
      pq_id: number;
      pq_no: string;
      pq_date: string;
      incoterm: string;
      buyer_consignee_yn: string;
      consignee_warehouese: string;
      delivery_address: string;
      maintained_by: string;
    }

    interface MyDataStructure {
      id: number;
      mfg_company_json: Record<string, string | number>[];
      category_id: string;
      brand_ids: Record<string, string | number>[];
      required_qty: string;
      uom: Record<string, unknown>;
      remarks: string;
      specifications: Record<string, string | number>[];
      prudct_name: string;
      standard_id: number;
      standard_attribute_id: any;
      category_attribute_json: Record<string, string | number>[];
    }

    const formData = ref({
      uom_select: "",
      sjvd_date: new Date(),
      pr_remarks: "",
    });

    const rules = ref({});
    const getPurchaseQuotationDetails = async () => {
      debugger;
      loading.value = true;
      try {
        var values = {
          pq_id: props.id,
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_PURCHASE_QUOTATION_DETAILS, values)
          .then((data) => {
            data = data.data;
            console.log("total data:" + JSON.stringify(data));
            tableData.value = [];
            var resultsM = ref<Array<TableData>>([]);

            for (let j = 0; j < data.length; j++) {
              resultsM.value = Array({
                sr_no: data[j]["sr_no"],
                pq_detail_id: data[j]["pq_detail_id"],
                pq_id: data[j]["pq_id"],
                request_ref: data[j]["request_ref"],
                mfg_company_name: data[j]["mfg_company_name"],
                category_name:
                  data[j]["category_name"] +
                  " - " +
                  data[j]["category_short_name"],
                category_short_name: data[j]["category_short_name"],
                description: data[j]["description"],
                pq_quantity: data[j]["pq_quantity"] + " " + data[j]["uom"],
                uom: data[j]["uom"],
                status_id: data[j]["status_id"],
              });

              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loading.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loading.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    async function getPurchaseRequisitonListDetail() {
      debugger;
      let params = {
        pq_id: props.id,
        user_id: 1,
        page: 1,
        records_per_page: 10,
      };
      store
        .dispatch(ActionsFi.CUST_GET_PURCHASE_QUOTATION_LIST_DETAILS, params)
        .then(({ data }) => {
          pqData.value = data;
        })
        .catch((response) => {
          console.log(response);
        });
    }

    rules.value = {
      sjvd_date: [
        {
          required: true,
          message: "Requsition Date is required",
          trigger: "change",
        },
      ],
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          // await setCompanyData(formData.value);
          alert("form submited sucessfully");
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      formRef,
      rules,
      myData,
      tableData,
      pqData,
      getPurchaseQuotationDetails,
      submit,
    };
  },
});
